
import _ from 'lodash'
import axios from 'axios'
import Config from './Config'

let CryptoJS = require("crypto-js")

const Gen = {
  baseUrl(){
    return process.env.VUE_APP_MODE == 'local' ? location.origin : process.env.VUE_APP_BASE_URL
  },
  apiUrl() {
    return process.env.VUE_APP_API_URL
  },
  apiHeader() {
    return {
      'X-API-KEY': 'Basic PkdalJ12fdUy2lOGEvGAS1aA1adj0fffLmx2gQQ4p9asY1Pc1oumMM2jLBAjkAdc21',
      'X-Frame-Options': 'sameorigin',
      'X-XSS-Protection': '1; mode=block',
      'X-Content-Type-Option': 'nosniff',
      'Content-Security-Policy': "script-src 'self'",
      'Strict-Transport-Security': 'max-age=31536000;includeSubDomains',
    }
  },
  apiRest(path, config = {}, method = "get") {
    let options = {
      baseURL: process.env.VUE_APP_API_URL,
      headers: {
        ...Gen.apiHeader(),
        'X-IS-CONTENT': config?.headers?.XISCONTENT ?? "",
      },
      method: method.toLowerCase(),
      url: '/api/bo' + path,
    }
    let data = {}
    let params = {}
    let headers = {}

    if (config.data) {
      options.method = "post"
      data = _.assign(data, config.data)
      data = {
        data: JSON.stringify({
          payload: Gen.CryptoJSAesEncrypt(
            Config.encrypt_key,
            JSON.stringify(data)
          )
        })
      }
    }
    if (config.params) params = _.assign(params, config.params)
    if (config.headers) headers = _.assign(headers, config.headers)

    params = _.assign({ token: Gen.getCookie("u_auth") || "" }, params || {})

    if (_.keys(data).length) options.data = data
    if (_.keys(params).length) options.params = params
    if (_.keys(headers).length) options.headers = headers

    return axios(options)
  },
  CryptoJSAesEncrypt(pass, value) {
    var salt = CryptoJS.lib.WordArray.random(256)
    var iv = CryptoJS.lib.WordArray.random(16)
    var key = CryptoJS.PBKDF2(pass, salt, { hasher: CryptoJS.algo.SHA512, keySize: 64 / 8, iterations: 999 })
    var encrypted = CryptoJS.AES.encrypt(value, key, { iv: iv })
    var data = {
      ciphertext: CryptoJS.enc.Base64.stringify(encrypted.ciphertext),
      salt: CryptoJS.enc.Hex.stringify(salt),
      iv: CryptoJS.enc.Hex.stringify(iv)
    }
    return JSON.stringify(data)
  },
  putStorage(key, value) {
    localStorage.setItem(key, JSON.stringify(value))
  },
  getStorage(key, def = "") {
    var item = localStorage.getItem(key)
    if (!item) return def
    return JSON.parse(localStorage.getItem(key))
  },
  objectToQuery(obj) {
    const results = []
    _.forOwn(obj, (value, key) => {
      if (Array.isArray(value)) {
        _.forOwn(value, value => { results.push(`${key}=${value}`) })
      } else {
        results.push(`${key}=${value}`)
      }
    })
    return results.join('&')
  },
  getCookie(name) {
    var nameEQ = name + "="
    var ca = document.cookie.split(';')
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i]
      while (c.charAt(0) == ' ') c = c.substring(1, c.length)
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length)
    }
    return null
  },
  setCookie(name, value, num = 1, type = "day") {
    try{
      var expires = ""

      var cookie_domain = ""
      if (process.env.VUE_APP_MODE=='local'){
        console.log(Gen.baseUrl())
        var a = document.createElement('a')
        a.href = Gen.baseUrl()
        cookie_domain = a.hostname
      }else{
        cookie_domain = location.hostname
      }

      var date = new Date()
      if (type == "day") {
        date.setTime(date.getTime() + (num * 24 * 60 * 60 * 1000))
        expires = "; expires=" + date.toUTCString()
      } else if (type == "minute") {
        date.setTime(date.getTime() + (num * 60 * 1000))
        expires = "; expires=" + date.toUTCString()
      }

      document.cookie = name + "=" + (value || "") + expires + "; path=/; domain=" + cookie_domain
    } catch (err){
      console.log(err)
    }
  },
  delay: (function () {
    var timer = 0
    return function (callback, ms) {
      clearTimeout(timer)
      timer = setTimeout(callback, ms)
    }
  })(),
  interval(callback, speed, timeout) {
    var time = 0
    var i = setInterval(() => {
      time += speed
      try {
        callback()
      } catch (e) {
        // statement
      }
      if (time == timeout) clearInterval(i)
    }, speed)
    return new Promise(resolve => { setTimeout(() => { resolve }, timeout) })
  },
  randomStr(length) {
    var result = ''
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    var charactersLength = characters.length
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength))
    }
    return result
  },
}

global.Gen = Gen

export default Gen